@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

$RickBlack: #07070A;
$Maroon: #B7245C;
$ForestGreen: #248232;
$BlueMunsell: #6891A6;
$CornflowerBlue: #253C78;

$debugCss: 0;
$debugMobile: 0;

.centrarColumna {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centrarFila {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  @if $debugCss>=1 {
    outline: 1px solid red;
  }

  @if $debugCss==2 {
    background-color: rgba(255, 0, 0, 0.1);
  }


}

a {
  text-decoration: none;
  color: inherit;
}

.icono {
  width: 25px;
  height: 25px;
}

button {
  font-size: inherit;
  background-color: inherit;
  outline: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: 2px solid green;
  }

  &:active {
    outline: 2px solid blue;
  }
}

.afterEstilos {
  background-color: #ccc;
  color: $CornflowerBlue;
  padding: 0.5rem;
  border: 2px solid $BlueMunsell;
  border-radius: 5px;
  position: absolute;
  white-space: nowrap;
  z-index: 10;
}

img,
svg {
  width: 25px;
  object-fit: cover;
}

html {
  scroll-behavior: smooth;
}


body #root {
  background-color: #eee;
  font-family: 'Roboto', sans-serif;

  font-size: clamp(1rem, 2vw + 1rem, 1.5rem);

  display: grid;
  //grid-template-rows: repeat(4, auto);
  //grid-template-rows: 75px auto auto;

  grid-template-areas:
    "header"
    "main"
    "footer";

  min-height: 100vh;


  header {
    grid-area: header;
    top: 0;
    z-index: 10;
    background-color: #fff;

    border-bottom: 3px solid #ccc;
    box-shadow: 0 5px 25px black;
    @extend .centrarColumna;
    justify-content: space-between;

    div {
      padding: 0.5rem 0;
      width: 90%;
      max-width: 400px;
      display: flex;
      justify-content: center;
    }

    .header__logo {
      margin-top: 0.25rem;

      img {
        min-width: 75px;
        width: 30vw;
        max-width: 150px;
      }
    }

    .header__social-container {
      //margin: 1rem 0;
      //background-color: lightblue;
      display: flex;
      justify-content: space-around;

      a {
        width: 50px;
        aspect-ratio: 1;
        display: flex;
        justify-content: center;

        img {
          align-self: center;
          width: 70%;
          height: 70%;
        }

        &:hover {
          transform: scale(1.2);
          border-bottom: 4px solid blueviolet;
        }
      }
    }

    .header__iconos {
      display: flex;
      justify-content: space-around;

      div {
        width: fit-content;
      }

      &-info,
      &-user {
        a {
          @extend .centrarColumna;
          text-align: center;
        }
      }



      &-carrito {
        span {
          padding: 0.5rem;
          background-color: red;
          border-radius: 50%;
          font-weight: 500;
          text-align: center;
          color: white;
        }
      }
    }
  }

  .filtros {
    grid-area: filtros;

    @extend .centrarFila;
    width: 100%;
    height: 50px;
    outline: 1px solid #ccc;

    div {
      @extend .centrarFila;
      width: 30%;
      height: 100%;

      // input[type="checkbox"] {
      //   width: 100px;
      //   height: 50px;
      // }

      input[type="text"] {
        background-color: inherit;
        text-align: center;
        outline: none;
        //width: 100%;
        height: 100%;

      }
    }

    justify-content: space-around;
    margin: 1rem 0;
  }


  main {
    @extend .centrarColumna;
    grid-area: main;
    width: 100%;

    .spinner {
      height: 60vh;
      width: 90vw;
      @extend .centrarColumna;
    }

    .productsFilterContainer {
      background-color: #fff;
      outline: 1px solid #ccc;
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      padding: 0.5rem;

      span {
        min-width: 150px;
        //background-color: red;
        display: flex;
        justify-content: space-around;
        align-items: center;

        label {
          cursor: pointer;

          input[type="checkbox"] {
            margin: 0.5rem;
            cursor: inherit;
            width: 20px;
            height: 20px;
          }
        }
      }

      input {
        background-color: inherit;
        text-align: center;
        color: #0066cc;
      }



      //margin: 0 2rem;
    }

    .productsContainer {
      @extend .centrarFila;
      flex-wrap: wrap;
      padding: 0.5rem;
      margin: 0.5rem;
      min-height: 70vh;

      article {
        position: relative;
        background-color: #fff;
        font-size: 1rem;
        outline: 1px solid #ccc;
        @extend .centrarColumna;
        justify-content: space-around;
        //padding: 0.5rem;
        margin: 0.5rem;
        width: 250px;
        //height: 400px;
        min-height: 300px;

        &:hover {
          outline: 1px solid black;
          transition: all .3s;

          .article__title {
            color: green;
            font-weight: 500;
          }

          .sinStock {
            background-color: #000;
          }
        }

        .article__title {
          padding: 0.5rem;
          width: 100%;
          height: 50px;
          font-size: 1rem;
          //background-color: darkblue;
          color: #0066cc;
          text-align: center;
        }

        .article__category {
          width: 100%;
          @extend .centrarFila;
          justify-content: space-between;

          //background-color: cyan;
          color: #aaa;
          text-align: end;
          font-size: 0.8rem;
          //width: 40%;
        }

        //background-color: yellow;
        .article__img-container {
          width: 100%;
          @extend .centrarColumna;

          img {
            width: 100%;
            aspect-ratio: 16/9;
            //aspect-ratio: 2;
            object-fit: scale-down;

            &:hover {
              object-fit: cover;
              //border: 3px solid red;
            }
          }
        }

        .article__price {
          //background-color: red;
          color: darkgreen;
          font-size: 1.5rem;
        }


        .sinStock {
          //background-color: #ccc;
          background-color: rgba(204, 204, 204, .8);
          color: #fff;
          padding: 0.5rem;
          position: absolute;
          top: 0;
          right: 0;
          border-bottom-left-radius: 15px;
        }

        .article__buttonsContainer {
          position: relative;
          //border: 1px solid red;
          //background-color: lightblue;
          width: 80%;
          max-height: 50px;
          @extend .centrarFila;
          justify-content: space-evenly;

          a {
            position: relative;

            svg {
              width: 2rem;
              aspect-ratio: 1;

              &:hover {
                fill: darkgreen;
              }
            }

            &.mas_informacion {
              &:hover {
                &::after {
                  padding: 0.5rem;
                  background-color: black;
                  color: white;
                  position: absolute;
                  bottom: 100%;
                  left: -50%;
                  text-overflow: none;
                  border-radius: 10px;
                  content: "Más Información";
                }
              }
            }

            &.agregar_carrito {
              &.conStock {
                &:hover {
                  &::after {
                    padding: 0.5rem;
                    background-color: black;
                    color: white;
                    position: absolute;
                    bottom: 100%;
                    left: -50%;
                    content: "Agregar al carrito";
                  }
                }
              }

              &.noStock {
                svg {
                  fill: #ccc;
                }
              }

            }
          }
        }
      }
    }

    .itemDetalles {

      margin-top: 2rem;
      padding: 0.5rem;

      border: 3px solid black;
      border-radius: 10px;
      background-color: #fff;
      width: 85vw;

      //display: flex;
      @extend .centrarColumna;
      //aca

      .itemDetalles__titulo {
        // grid-area: titulo;
        text-align: center;
        @extend .centrarColumna;
        color: #0066cc;
        margin: 0.5rem;
      }

      .itemDetalles__imagen {
        // grid-area: foto;
        @extend .centrarColumna;

        iframe,
        .imagenesContenedor {
          outline: 1px solid #ccc;
          width: 80vw;
          //width: 100%;
          aspect-ratio: 16/9;

          ul {
            li {
              img {
                max-height: 300px;
                aspect-ratio: 1;
                object-fit: contain;
              }
            }
          }
        }
      }

      .itemDetalles__datos {
        // grid-area: datos;
        @extend .centrarColumna;
        justify-content: space-evenly;
        width: 100%;

        .detalles {
          padding: 0.5rem;
          overflow: auto;
          outline: 1px solid #ccc;
          height: 300px;
          font-size: 1rem;
          cursor: text;
        }

        .infoAdjunta {
          outline: 1px solid #333;
          background-color: blueviolet;
          color: #fff;
          padding: 0.5rem;
          margin: 1rem 0;
        }

        .discontinuado {
          background-color: red;
          width: 100%;
          padding: 0.5rem;
          color: #eee;
          text-align: center;
        }

        .precio {
          margin: 2rem 0;
          font-size: 2rem;
          color: $ForestGreen;
          font-weight: 500;
          //background-color: red;
        }

        .fullMsg,
        .meliButton {
          width: 80%;
          background-color: #fff159;
          color: #08178a;
          outline: 1px solid #333;
          padding: 0.5rem;
          margin: 0.5rem 0;
          font-size: 1rem;
        }

        .meliButton {
          text-align: center;
        }
      }

      .itemDetalles__share {
        //grid-area: share;
        width: 50%;
        position: relative;
        @extend .centrarFila;
        justify-content: space-around;

        a {
          //border: 1px solid green;
          height: 100%;

          svg {
            //border: 1px solid blue;
            height: 100%;
            fill: green;
          }
        }

        .whatsapp-consultar {
          &:hover {
            &::after {
              @extend .afterEstilos;
              position: absolute;
              bottom: 110%;
              right: 0%;
              content: "Consultanos sobre este producto";
            }
          }
        }

        .whatsapp-recomendar {
          &:hover {
            &::after {
              @extend .afterEstilos;
              position: absolute;
              bottom: 110%;
              right: 0%;
              content: "Recomendar este producto a un amigo";
            }
          }
        }
      }
    }

    a {
      &.meliButton {
        outline: 1px solid #333;
        cursor: pointer;
        background-color: #fff159;
        color: #08178a;
        padding: 0.5rem;
        font-size: 1rem;
      }
    }


    //ItemDetailContainer => MoreProducts
    .miniatura__container {
      margin-top: 2rem;
      z-index: 10;
      @extend .centrarFila;
      justify-content: space-around;
      width: 100%;

      transition: all 1s;

      .miniatura__individual {
        @extend .centrarColumna;
        justify-content: space-between;
        width: 40vw;
        max-width: 200px;

        aspect-ratio: 1;

        margin: 0 1rem;
        outline: 1px solid #ccc;
        box-shadow: 2px 2px 10px black;
        transition: all 1s;

        background-color: #fff;

        &:hover {
          outline: 2px solid black;
          transform: translateY(-15px);
          box-shadow: 10px 10px 20px black;
          transition: all .3s;
        }

        .miniatura__titulo {
          // @extend .centrarCFila;
          height: 20%;

          font-size: 0.7rem;
          text-decoration: none;
          text-align: center;

          overflow: hidden;
        }

        .miniatura__img-container {
          @extend .centrarColumna;


          // outline: 2px solid blue;
          //height: 6rem;
          //height: 50%;
          //height: 50%;
          // mix-blend-mode: multiply;
          height: 60%;

          img {
            //width: 100px;

            //height: 6rem;
            width: 100%;
            height: 100%;
            object-fit: scale-down;

            //width: 100%;
            //height: 100px;
          }
        }

        .miniatura__individual-precio {
          //height: 2rem;
          height: 20%;
          //text-align: center;

          //background-color: lightcoral;

          &::after {
            content: " $";
          }
        }
      }
    }

    //UserLogin => UserData
    .userData-container {
      margin-top: 1rem;
      width: 100%;
      min-height: 50vh;
      @extend .centrarColumna;
      background-color: #fff;

      .userData {
        @extend .centrarFila;
        background-color: darkgreen;
        color: #ccc;
        width: 80%;
        height: 75px;
        justify-content: space-between;
        font-size: 1rem;

        .img-container {
          //border: 2px solid $Maroon;
          width: 75px;
          height: 75px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .logoutButtonContainer {
          height: 100%;

          button {
            cursor: pointer;
            outline: none;
            border: none;
            background-color: inherit;
            height: 100%;
            @extend .centrarColumna;
            color: inherit;

            svg {
              height: 50%;
              fill: white;
            }
          }
        }

      }

      .userOrders {
        margin-top: 2rem;
        width: 80%;
        border: 2px solid black;

        .sinCompras-container {
          @extend .centrarColumna;
        }


        .orden {
          position: relative;
          font-size: 1rem;
          padding: 0.5rem;

          &.impar {
            background-color: #ddd;
          }


          &:hover {
            background-color: lightblue;

            .whatsapp-consulta {
              svg {
                fill: blue;
              }
            }
          }

          svg {
            width: 25px;
            height: 25px;
          }

          .renglon {
            display: flex;
            justify-content: space-between;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            }
          }


          .botones-contenedor {
            display: flex;
            justify-content: space-around;
          }

          .whatsapp-consulta,
          .orden-cancelacion,
          .clipboard {
            text-align: center;

            &.primerIcono {
              position: relative;

              &:hover {
                &::after {
                  @extend .afterEstilos;
                  position: absolute;
                  bottom: 100%;
                  right: -50%;

                }
              }
            }
          }

          .whatsapp-consulta {
            &.primerIcono {
              &:hover {
                &::after {
                  content: "Consultar en Whatsapp";
                }
              }
            }
          }

          .orden-cancelacion {
            &.primerIcono {
              &:hover {
                &::after {
                  content: "Cancelar Pedido";
                }
              }
            }
          }

          .clipboard {
            &:hover {
              &::after {
                content: "Copiar al portapapeles";
              }
            }
          }
        }

        details summary {
          cursor: pointer;

        }

        details[open] {
          summary {
            background-color: #ccc;
          }
        }
      }

      form {
        border: 2px solid black;
        margin-top: 3rem;
        padding: 2rem;
        background-color: white;
        @extend .centrarColumna;
        width: 80%;

        .botonAutoCompletar {
          position: relative;
          outline: 1px solid #333;
          background-color: blueviolet;
          color: #fff;
          padding: 0.5rem;
          margin: 1rem 0;

          &:after {
            z-index: 10;
            position: absolute;
            top: 110%;
            left: 0;
            background-color: white;
            color: red;
            font-size: 1rem;
            white-space: nowrap;
            content: "*(requiere localización)";
          }
        }

        .form__field {
          width: 100%;
          @extend .centrarFila;
          flex-direction: column-reverse;
          border-bottom: 1px solid #aaa;
          margin: 2rem 0.5rem;
          //margin-top: 0.5rem;
          font-size: 1rem;

          input {
            width: 60%;
            text-align: center;
            background-color: inherit;
            outline: none;
            border: none;

            &.form-error {
              font-size: 1.5rem;
              // color: white;
              background-color: rgba(255, 0, 0, 0.3);
            }
          }

          input[type='checkbox'] {
            max-height: 30px;
          }

          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          label {
            width: 100%;
            text-align: center;
            color: #aaa;
          }


          //TODO: combinar con el de arriba?
          input {
            height: 100%;
            margin-top: 1rem;
            // width: 100%;

            &:focus {
              outline: 1px dotted black;
            }
          }


          &:hover {
            border-bottom: 1px solid blue;

            input {
              color: blue;
            }

            label {
              color: blue;
              transition: all .3s;

            }
          }
        }

        .submitButton {
          cursor: pointer;
          padding: 0.5rem;
          font-size: 1rem;
          background-color: green;
          color: #fff;
          font-weight: 700;
        }
      }


      .loginButtonContainer {
        outline: none;
        border: none;

        width: fit-content;
        max-width: 150px;
        aspect-ratio: 1;
        padding: 1rem;

        @extend .centrarColumna;
        color: blueviolet;
        border: 2px solid blueviolet;

        box-shadow: 10px 10px 10px 0 blueviolet;
        cursor: pointer;
        background-color: inherit;

        svg {
          width: 100%;
          height: 100%;
          fill: blueviolet;
        }

        font-size: 2rem;
        white-space: nowrap;

        &:active {
          transform: translate(10px, 10px)
        }
      }
    }

    .carritoContainer {
      margin-top: 3rem;
      width: 100%;
      min-height: 60vh;
      @extend .centrarColumna;
      justify-content: flex-start;

      .productos {
        //background-color: pink;
        width: 90%;
        background-color: white;

        color: green;

        &__producto {
          @extend .centrarColumna;
          margin: 2rem 0.5rem;
          justify-content: space-between;
          border-bottom: 1px solid black;

          img {
            width: 100px;
            height: 100px;
            object-fit: scale-down;
          }

          a {

            text-align: center;
            width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p {

            width: 150px;
            text-align: center;
          }

          .selectorProveedorEnvio {
            background-color: pink;

          }

          &.envioGratis {
            background-color: rgba(51, 51, 51, 0.1);

            a,
            p {
              text-decoration: line-through;
            }
          }
        }
      }

      &__subTotal {
        font-size: 2rem;
        width: 80%;
        min-height: 50px;
        @extend .centrarFila;
        justify-content: flex-end;
      }

      &__medioDePago {
        position: relative;
        font-size: 1.5rem;
        width: 80%;
        min-height: 50px;
        @extend .centrarColumna;

        p {
          flex-grow: 1;
        }

        select {
          background-color: white;
          font-size: 1.5rem;
        }

        .medioDePagoArrepentimiento {
          font-size: 1rem;
          color: red;

          &.oculto {
            display: none;
          }

        }
      }

      .botonFinalizarCompra {
        margin: 16px;
        outline: 3px solid #ccc;
        padding: 1rem;
        background-color: green;
        color: white;

        &.noLogueado {
          background-color: red;
        }

        &:disabled {
          background-color: #ccc
        }
      }
    }


    .itemCountContainer {
      .itemCount {
        @extend .centrarFila;
        outline: 1px solid black;

        button {
          cursor: pointer;
          border: none;
          outline: none;
          //background-color: pink;
          width: 2rem;
          height: 2rem;
          font-size: 2rem;
          text-align: center;
          // background-color: pink;
          // color: red;
        }

        input {
          font-size: 1.5rem;
          //height: 80%;
          max-width: 3rem;
          text-align: center;
          border: none;
          outline: none;

        }

        input:out-of-range {
          background-color: red;
          color: white;
        }

        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }


      .botonAgregarAlCarrito {
        margin: 0.5rem;

        svg {
          width: 40px;
          fill: green;
        }

        text-align: center;
      }
    }


    //Main => Admin
    .adminPage {
      //flex-grow: 1;
      // background-color: darkblue;
      margin: 0 auto;
      width: 100%;
      min-height: 50vw;
      padding: 1rem;
      @extend .centrarColumna;
      justify-content: space-around;

      &__credenciales {
        border: 5px solid $BlueMunsell;
        padding: 2rem;
        width: fit-content;
        //min-height: 400px;
        @extend .centrarColumna;

        //justify-content: space-around
        input {
          width: 80%;
          margin: 0.5rem;
          padding: 0.5rem;
          background-color: $CornflowerBlue;
          text-align: center;
          color: $ForestGreen;
          border: none;
          //outline: none;
          font-size: 1.5rem;

          &::placeholder {
            color: $ForestGreen;
          }
        }

        button {
          color: $ForestGreen;
          border: none;
          margin: 0.5rem;
          width: 80%;
          padding: 1rem;
          background-color: $CornflowerBlue;
        }
      }

      &__section {
        margin-bottom: 3rem;
        width: 100%;

        ul {
          li {
            background-color: $ForestGreen;

            p {
              color: $RickBlack;
              text-align: center;
              font-size: 1rem;

              &.nombre {
                // width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }



            input {
              background-color: red;
              font-size: 1rem
            }
          }
        }

        &.ordenes-container,
        &.productos-container,
        &.usuarios-container {
          //background-color: pink;
          //grid-row: 50px;

          details {
            display: flex;
            font-size: 1rem;
            border: 3px solid transparent;

            //flex-direction: column;
            summary {
              cursor: pointer;
              display: flex;
              justify-content: space-between;

            }
          }

          p,
          input,
          a,
          select {
            color: $RickBlack;
            font-size: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            align-self: center;
            background-color: inherit;

            //outline: 1px solid #ccc;
          }

          select {
            height: 100%;
            max-width: 200px;
          }

          .orden {
            position: relative;
            border: 3px solid transparent;

            &:hover {
              border: 3px solid $Maroon;
            }

            background-color: $ForestGreen;

            &.par {
              background-color: #68B574;
            }


            display: flex;
            flex-direction: column;
            justify-content: center;



            svg {
              width: 25px;
              height: 25px;
            }


            img {
              width: 50px;
              height: 50px;
            }

            a select option {
              text-align: left;
            }

            .titulo {
              text-align: left;
            }

            .botones-contenedor {
              display: flex;
              justify-content: center;

              button {
                margin: 0 1rem;

                &:disabled {
                  fill: #888;
                  cursor: not-allowed;
                }
              }
            }

            .paqar,
            .email,
            .whatsappAdmin,
            .eliminar,
            .pdf,
            .guardar {
              position: relative;

              &:hover {
                &::after {
                  @extend .afterEstilos;
                  right: -10%;
                  bottom: 100%;
                }
              }
            }

            .paqar {
              &:hover {
                &::after {
                  content: "Etiqueta de envío";
                }
              }
            }

            .email {
              &:hover {
                &::after {
                  content: "Enviar correo a este usuario";
                }
              }
            }

            .whatsappAdmin {
              &:hover {
                &::after {
                  content: "Enviar whtasapp con seguimiento a este usuario";
                }
              }
            }

            .eliminar {
              &:hover {
                &::after {
                  content: "Eliminar orden";
                }
              }
            }

            .pdf {
              &:hover {
                &::after {
                  content: "Crear PDF de orden";
                }
              }
            }

            .guardar {
              &:hover {
                &::after {
                  content: "Actualizar en FireStore";
                }
              }
            }
          }
        }

        &.productos-container {
          .orden {
            display: grid;
            grid-template-columns: 50px 3.5fr 1fr 3fr 8fr 1fr 1fr 1fr repeat(4, 0.6fr);
          }

        }
      }

      .modalEdicionContainer {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        .modalConfiguracion {
          border: 5px solid black;
          border-radius: 15px;
          padding: 1rem;
          background-color: #eee;
          width: 80%;
          //min-height: 50vh;

          display: flex;
          flex-direction: column;
          position: relative;

          .titulo {
            //background-color: black;
            text-align: center;
            font-size: 2rem;
            margin: 1rem;
          }

          label {
            border-bottom: 2px solid #aaa;
            margin: 0.5rem auto;
            width: 80%;
            display: flex;
            justify-content: space-between;

            input {
              background-color: inherit;
              outline: none;
              // outline: 1px solid #aaa;
              border: none;
              text-align: center;

              &:focus {
                background-color: lightblue;
              }
            }

            &:hover {
              background-color: lightblue;
            }
          }

          .cerrar {
            background-color: black;
            color: white;
            width: 2rem;
            height: 2rem;
            padding: 0;
            margin: 0;
            position: absolute;
            border: 2px solid black;
            outline: none;
            border-bottom-left-radius: 10px;
            top: 0;
            right: 0;

            svg {
              fill: white;
            }

            &:hover {
              border-radius: 10px;
              background-color: white;

              svg {
                fill: black;
              }
            }
          }

          ul {
            width: 100%;
            background-color: white;
            color: black;

            li {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(3, 4rem) auto 10rem;
              border: 2px solid black;
              background-color: white;
              color: black;

              input {
                text-align: center;
                border: none;
                outline: none;
                background-color: #ccc;
                color: black;
              }
            }
          }
        }

      }
    }

    .instructivo {
      margin-top: 2rem;
      background-color: white;
      border: 2px solid black;
      width: 80%;

      h4 {
        background-color: $ForestGreen;
        color: #ddd;
        padding: 0.5rem;
      }

      ol {
        li {
          padding: 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            height: 266px;
            width: 200px;
            object-fit: contain;
          }

          details {
            width: 100%;
            //background-color: ;
            color: $CornflowerBlue;
            font-size: 1.2rem;
          }
        }
      }

      .tarea-completada {
        //background-color: $ForestGreen;
        text-decoration: line-through;
      }
    }


    .error404-img {
      width: 100%;
      min-height: 50vh;
      @extend .centrarColumna;

      img {
        margin: auto;
        width: 80%;
        max-width: 500px;
        object-fit: scale-down;
      }


    }

    .caraTriste {
      background-color: inherit;
      @extend .centrarColumna;

      img {
        width: 50px;
      }

    }
  }

  footer {
    margin-top: 5rem;
    grid-area: footer;

    display: flex;
    @extend .centrarFila;
    justify-content: space-evenly;
    flex-wrap: wrap;

    font-size: clamp(0.7rem, 2vw, 1.1rem);
    width: 100%;
    background-color: black;
    padding: 1rem;
    text-align: center;
    color: white;
    font-weight: 400;

    a {
      min-width: 250px;
    }
  }
}


@media (min-device-width: 768px) {
  body #root {
    @if $debugMobile==1 {
      background-color: lightblue;
    }

    header {
      position: sticky;
      // padding: 0 2rem;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      height: 100px;

      div {
        width: fit-content;
      }

      .header__iconos {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
      }

      .active {
        border-bottom: 3px solid blue;

      }
    }

    main {
      .itemDetalles {
        //background-color: pink;
        display: grid;
        grid-template-columns: 1.618034fr 1fr;
        //grid-template-columns: 30vw 40%;
        //grid-template-columns: 50% 50%;
        grid-template-rows: auto auto auto;

        grid-template-areas: "foto titulo"
          "foto datos"
          "nada share";

        &__titulo {
          grid-area: titulo;
        }

        &__imagen {
          grid-area: foto;

          .imagenesContenedor {
            max-width: 500px;
            height: 400px;

            .carousel-root {
              ul li img {
                aspect-ratio: 16/9;
              }
            }
          }

          .iframeContainer {
            //width: 500px;

            iframe {
              width: 500px;
            }
          }
        }

        &__datos {
          grid-area: datos;
          //background-color: lightcoral;
        }

        &__share {
          grid-area: share;
          border: 1px solid #ccc;
          //padding: 0.5rem;
          margin: auto;
        }
      }



      /*******/

      .userData-container {

        //background-color: pink;
        form {
          width: 80%;
          //background-color: lightcyan;

          .form__field {
            font-size: 1.2rem;
            //background-color: red;
            flex-direction: row;

            input {
              font-size: inherit;
              margin-top: 0;
            }

            label {
              width: 35%;
              text-align: left;
            }
          }
        }
      }

      .carritoContainer {
        .productos {
          &__producto {
            flex-direction: row;
          }
        }

        &__medioDePago {
          flex-direction: row;

          .medioDePagoArrepentimiento {
            position: absolute;
            top: 100%;
            right: 0;
          }
        }

        .botonFinalizarCompra {
          margin-top: 2rem;
        }
      }
    }

    footer {
      max-height: 50px;
      flex-wrap: nowrap;
    }
  }
}